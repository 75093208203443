import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';
import { differenceInSeconds, format } from 'date-fns';
import capitalize from 'lodash/capitalize';
import { $path } from 'remix-routes';

import { type DtoRoleplayHistory } from '@lp-lib/api-service-client/public';

import { apiService } from '../services/api-service';
import { tokenWithRedirect } from '../utils/router';
import { TimeUtils } from '../utils/time';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const url = new URL(action.request.url);
  const blockId = url.searchParams.get('block-id');
  const uid = url.searchParams.get('uid');

  const histories = await tokenWithRedirect(
    async () => {
      const resp = await apiService.block.queryRoleplayHistories({
        blockId,
        uid,
      });
      return resp.data.histories;
    },
    action.request.url,
    {
      preferRedirect: 'login',
      requireAuthentication: true,
    }
  );

  return {
    histories,
  };
}

function HistoriesList(props: { histories: DtoRoleplayHistory[] }) {
  return (
    <div className='text-white w-full h-full p-12 overflow-auto scrollbar'>
      <header className='flex justify-between items-center'>
        <h1 className='font-bold text-4xl'>Roleplay History</h1>
      </header>

      <main className='w-full mt-7'>
        <table className='mt-2 w-full'>
          <thead>
            <tr className='text-left h-12 text-base text-bold'>
              <th className='pl-2.5'>Block Title</th>
              <th>User</th>
              <th>Duration</th>
              <th>Result</th>
              <th>Evaluation</th>
              <th>Played At</th>
            </tr>
          </thead>
          <tbody>
            {props.histories?.map((history) => (
              <tr
                key={history.id}
                className='w-full h-10 text-sms cursor-pointer hover:bg-lp-gray-002'
                onClick={() => {
                  window.open(
                    $path('/roleplay/histories/:id', { id: history.id }),
                    '_blank'
                  );
                }}
              >
                <td className='pl-2.5'>
                  <p className='max-w-40 truncate'>{history.blockName}</p>
                </td>
                <td>{history.userName}</td>
                <td>
                  {TimeUtils.DurationFormattedHHMMSS(
                    differenceInSeconds(
                      new Date(history.endedAt),
                      new Date(history.startedAt)
                    ) * 1000
                  )}
                </td>
                <td>{capitalize(history.feedback?.result)}</td>
                <td>
                  {!!history.evaluation?.results.length
                    ? `${
                        history.evaluation?.results.filter(
                          (r) => r.result === 'success'
                        ).length
                      } of ${history.evaluation?.results.length} successful`
                    : 'N/A'}
                </td>
                <td>
                  {format(new Date(history.startedAt), 'MM/dd/yy h:mm aa')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {props.histories.length === 0 && (
          <div className='w-full mt-30 flex items-center justify-center text-secondary'>
            No Histories.
          </div>
        )}
      </main>
    </div>
  );
}

export function Component() {
  const data = useLoaderData<typeof clientLoader>();

  return <HistoriesList histories={data?.histories || []} />;
}
